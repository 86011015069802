import ServerEnv from '@leuven2030/framework/Server/ServerEnv';

function getEndpoint() {
  if (process.env.GRAPHQL_ENDPOINT) {
    return process.env.GRAPHQL_ENDPOINT;
  }
  if (ServerEnv.isDev) {
    return 'http://localhost:3020/api/graphql';
  }
  return `${ServerEnv.endpoint}/api/graphql`;
}

const GraphqlEndpoint = getEndpoint();
if (!GraphqlEndpoint) {
  console.warn('Could not find the graphql endpoint');
}
export default GraphqlEndpoint;
