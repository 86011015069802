import ServerPort from '@leuven2030/framework/Server/ServerPort';

const isDev = process.env.NODE_ENV
  ? process.env.NODE_ENV === 'development'
  : true;
const isServer = typeof window === 'undefined';

const ServerEnv = {
  isDev,
  isServer,
  isBrowser: !isServer,
  graphql: {
    secret: process.env.GRAPHQL_SERVICES_SECRET
  },
  aws: {
    accessKeyId:
      process.env.AWS_ACCESS_KEY_ID_PROJECT || process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey:
      process.env.AWS_SECRET_ACCESS_KEY_PROJECT ||
      process.env.AWS_SECRET_ACCESS_KEY,
    region:
      process.env.AWS_REGION_PROJECT || process.env.AWS_REGION || 'eu-central-1'
  },
  ...getHostDetails()
};

function getHostDetails() {
  if (isDev) {
    return {
      domain: 'localhost',
      endpoint: `http://localhost:${ServerPort}`
    };
  }
  const deployedDomain =
    process.env.APP_DOMAIN || process.env.NEXT_PUBLIC_VERCEL_URL;
  return {
    domain: deployedDomain,
    endpoint: `https://${deployedDomain}`
  };
}

export default ServerEnv;
