import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import PrismicApolloLink, {
  PrismicLinkOptions
} from '@leuven2030/framework/Prismic/PrismicApolloLink';
import GraphqlEndpoint from '@leuven2030/framework/Graphql/GraphqlEndpoint';
import AuthConfig from '@leuven2030/framework/Auth/AuthConfig';

export type GetLinkOptions = {
  prismic?: PrismicLinkOptions;
};

async function getAuthToken() {
  if (!AuthConfig.isValid()) {
    return;
  }
  const session = await Auth.currentSession().catch((err) => {
    if (err == 'No current user') {
      return;
    }
    if (!err) {
      return;
    }
    throw err;
  });
  if (!session) {
    return;
  }
  return session.getIdToken().getJwtToken();
}

function getLink(options: GetLinkOptions = {}) {
  const { prismic } = options;
  const prismicLink = PrismicApolloLink(prismic);
  const authLink = setContext(async () => {
    const Authorization = await getAuthToken();
    return {
      headers: { Authorization }
    };
  });
  const apiLink = ApolloLink.from([
    authLink,
    new HttpLink({
      uri: GraphqlEndpoint,
      credentials: 'same-origin'
    })
  ]);

  return ApolloLink.split(
    (operation) => {
      const context = operation.getContext();
      return context.clientName === 'prismic';
    },
    prismicLink,
    apiLink
  );
}

export default getLink;
