import { createTheme } from '@material-ui/core/styles';
import { theme } from 'twin.macro';

export const StyleMaterialTheme = createTheme({
  palette: {
    primary: {
      light: theme`colors.primary.300`,
      main: theme`colors.primary.500`,
      dark: theme`colors.primary.600`
    },
    secondary: {
      light: theme`colors.secondary.300`,
      main: theme`colors.secondary.500`,
      dark: theme`colors.secondary.600`
    }
  },
  overrides: {
    MuiCheckbox: {
      root: {
        padding: 0
      }
    },
    MuiAccordion: {
      root: {
        '&:before': null
      }
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        ':before': null
      }
    },
    MuiStepConnector: {
      vertical: {
        padding: null
      }
    },
    MuiStepContent: {
      root: {
        marginTop: null,
        paddingRight: null
      }
    },
    MuiStepper: {
      root: {
        'background-color': 'transparent',
        padding: 0
      }
    },
    MuiDialogContent: {
      root: {
        padding: null
      }
    },
    MuiFab: {
      label: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
      }
    },
    MuiAutocomplete: {
      listbox: {
        padding: null
      },
      option: {
        paddingTop: null,
        paddingLeft: null,
        paddingBottom: null,
        paddingRight: null
      }
    },
    MuiDialog: {
      paper: {
        margin: null
      },
      paperWidthSm: {
        maxWidth: null
      },
      paperScrollPaper: {
        maxHeight: null
      }
    },
    MuiInputBase: {
      root: {
        display: 'flex'
      },
      input: {
        padding: null,
        border: null,
        height: null
      }
    },
    MuiSvgIcon: {
      root: {}
    },
    MuiAvatar: {
      root: {
        fontSize: null,
        width: null,
        height: null
      },
      colorDefault: {
        color: null,
        'background-color': null
      }
    },
    MuiFormControlLabel: {
      root: {
        alignItems: null,
        marginLeft: null,
        marginRight: null
      },
      label: {
        width: '100%',
        marginLeft: 8
      }
    },
    MuiList: {
      padding: {
        'padding-top': 0,
        'padding-bottom': 0
      }
    },
    MuiTextField: {
      root: {
        'background-color': null
      }
    },
    MuiButton: {
      root: {
        'border-radius': 2,
        color: null,
        'text-transform': null,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
      }
    },
    MuiButtonBase: {
      root: {
        display: null,
        //color: null,
        'align-items': null,
        'justify-content': null,
        //'background-color': null,
        padding: null,
        'border-radius': null
      }
    }
  }
});
export default StyleMaterialTheme;
