import StyleMaterialProvider from '@leuven2030/framework/Style/StyleMaterialProvider';
import PageLoader from '@leuven2030/framework/Page/PageLoader';
import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import React, { useEffect } from 'react';
import { GlobalStyles } from 'twin.macro';
import ReactMoment from 'react-moment';
import { locale } from 'moment';
import dynamic from 'next/dynamic';
import { useApollo } from '@leuven2030/framework/apollo/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ServerEnv from '@leuven2030/framework/Server/ServerEnv';
import Analytics from '@leuven2030/framework/Analytics/Analytics';
import 'moment/locale/nl';

import * as yup from 'yup';
import yupPassword from 'yup-password';
yupPassword(yup);

const ImageLazy = dynamic(
  () => import('@leuven2030/framework/Image/ImageLazy'),
  { ssr: false }
);

ReactMoment.globalLocale = 'nl';
locale('nl');

const PageApp = ({ Component, pageProps, err, children }) => {
  const apolloClient = useApollo(pageProps);
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="app-domain" content={ServerEnv.domain} />
        <meta name="app-version" content={process.env.APP_VERSION} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <title>Leuven2030 App</title>
        {/* <script src="http://localhost:8097"></script> */}
      </Head>
      <GlobalStyles />
      <ImageLazy />
      <StyleMaterialProvider>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
          <ApolloProvider client={apolloClient}>
            <Analytics />
            <PageLoader />
            <Component {...pageProps} err={err} />
          </ApolloProvider>
        </GoogleReCaptchaProvider>
      </StyleMaterialProvider>
      {children}
    </>
  );
};

export default PageApp;
