import '@leuven2030/framework/styles/base.css';
import React from 'react';
import PageApp from '@leuven2030/framework/Page/PageApp';
import { Global, css } from '@emotion/react';

const PageAppLeuvenCool = (props) => {
  return (
    <PageApp {...props}>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800&family=Roboto:wght@300&display=swap"
        rel="stylesheet"
      />
      <Global
        styles={css`
          * {
            font-family: Roboto;
          }
          h1,
          h2,
          h3,
          h4,
          .font-primary,
          .prismic-text h1,
          .prismic-text h2,
          .MuiButtonBase-root {
            font-family: Montserrat;
          }
        `}
      />
    </PageApp>
  );
};
export default PageAppLeuvenCool;
