import { ThemeProvider } from '@material-ui/core/styles';
import React, { FC } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { cache } from '@emotion/css';
import { StylesProvider } from '@material-ui/styles';
import StyleMaterialTheme from '@leuven2030/framework/Style/StyleMaterialTheme';
const ThemeMaterialProvider: FC = ({ children }) => (
  <CacheProvider value={cache}>
    <ThemeProvider theme={StyleMaterialTheme}>
      <CssBaseline />
      <StylesProvider injectFirst>{children}</StylesProvider>
    </ThemeProvider>
  </CacheProvider>
);

export default ThemeMaterialProvider;
